var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-field", {
                  attrs: {
                    editable: false,
                    data: _vm.searchParam,
                    type: "user",
                    label: "수강자",
                    name: "attendeesId",
                  },
                  model: {
                    value: _vm.searchParam.attendeesId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "attendeesId", $$v)
                    },
                    expression: "searchParam.attendeesId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: { range: true, name: "period", label: "교육기간" },
                  model: {
                    value: _vm.searchParam.educationPeriod,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "educationPeriod", $$v)
                    },
                    expression: "searchParam.educationPeriod",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "동영상 교육 목록",
            tableId: "table",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
            checkDisableColumn: "disable",
            checkClickFlag: false,
            rowKey: "eduEducationId",
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "vodPlayTime"
                    ? [
                        _c(
                          "q-chip",
                          {
                            attrs: {
                              color: _vm.getPlayTimeColor(
                                props.row.vodTotalTime
                              ),
                              outline: "",
                              square: "",
                              clickable: false,
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.getPlayTime(props.row)) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "table-button" }, [
            _c(
              "div",
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", {
                      attrs: { label: "검색", icon: "search" },
                      on: { btnClicked: _vm.getList },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }